import React from 'react'
import { motion } from 'framer-motion'
import './transitionEffect.scss'
var stylingObject = {
    frame1: {
        backgroundColor: "var(--title-color)",
    },
    frame2:{
        backgroundColor:"var(--body-color)",
    },
    frame3:{
        backgroundColor:"var(--bg-blue-dark)",
    }
}
const TransitionEffect = () => {
  return (
    <>
        <motion.div className="fixed-frame frame1" style={stylingObject.frame1} 
            initial={{ x:'100%', width:'100%'}}
            animate={{ x:'0%', width:'0%' }}
            transition={{ duration: 0.8, ease:'easeInOut'}}
        /> 
        <motion.div className="fixed-frame frame2" style={stylingObject.frame2} 
            initial={{ x:'100%', width:'100%'}}
            animate={{ x:'0%', width:'0%' }}
            transition={{ delay:0.2, duration: 0.8, ease:'easeInOut'}}
        /> 
        <motion.div className="fixed-frame frame3" style={stylingObject.frame3} 
            initial={{ x:'100%', width:'100%'}}
            animate={{ x:'0%', width:'0%' }}
            transition={{ delay:0.4,duration: 0.8, ease:'easeInOut'}}
        /> 
    </>
  )
}

export default TransitionEffect
