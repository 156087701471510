import React, { useState, useEffect } from 'react';
import { client } from '../client';

const Info = () => {
  
  const [about, setAbout] = useState([])

  useEffect(() => {
    const query = '*[_type == "about"]'

    client.fetch(query)
      .then((data) => {
        setAbout(data[0].services)
      })

  }, []);

  return (
  <div className="about__info grid">
    {about.map((about, index) => (
      <div className="about__box" key={index}>
          <i className='bx bxs-award about__icon'></i>
          <h3 className="about__title">{ about.title}</h3>
          <span className="about__subtitle">{ about.description}</span>
      </div>

    ))}
    
  </div>
  )
}

export default Info
