import React from 'react'
import { motion } from 'framer-motion'

var stylingObject = {
  h3: {
    fontSize: "var(--h3-font-size)",
    fontWeight: "var(--font-normal)",
    
  }
}


class Heading3 extends React.Component {
    render(){
        return(

        <motion.h3 className="home__subtitle"
          style={ stylingObject.h3 }
          initial={{ y:-70, opacity: 0 }}
          whileInView={{ y:0, opacity:1 }} 
          transition={{ duration:0.7, delay: 0.45, type:"spring" }}
        >
          { this.props.subtitle }
        </motion.h3>
        )
    }
}

export default Heading3