import React from 'react'
import './footer.scss'
const Footer = () => {
  return (
    <div className='footer' id='footer'>
      <div class="carbon-tag__container container">
        <div id="wcb" class="carbonbadge wcb-d"></div>
        <script src="https://unpkg.com/website-carbon-badges@1.1.3/b.min.js" defer></script>
        ©François SARIN - 2024
		  </div>
    </div>
  )
}

export default Footer
