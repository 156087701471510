import React from 'react'
import { motion } from 'framer-motion'


class CtaButton extends React.Component {
    render(){
        return(
            <motion.a 
                href={this.props.href}
                className="button button--flex" 
                initial={{ y:70, opacity: 0 }}     
                whileInView={{ y:0, opacity:1 }} 
                transition={{ duration:0.8, delay: 0.15, type:"spring" }}
                >
                {this.props.label}
            </motion.a>
        )
    }
}

export default CtaButton
