import { Link } from 'react-router-dom';
import React, { Component } from 'react'
import './card.scss'
import { motion } from 'framer-motion'

export class Card extends Component {
  render() {
    return (
      <motion.div 
        className='card' 
        key={this.props.id}

      >
            <div className="card-image">
                <img src={ this.props.src } alt={ this.props.alt }  />
            </div>
            <div className="card-content">
                <h2 className='card-title'>{ this.props.title }</h2>
                <div className="card-tags">
                </div>
                <p className="card-description">{ this.props.description } </p>
            <div className="card-button">
              <Link to={ this.props.to } key={ this.props.slug } alt={ this.props.altLink } className='button--small button--flex'> + de détails </Link>
              <a href={ this.props.externLink } alt={ this.props.externLink } className='button--small__border button--flex'> Aller sur le site </a>
            </div>
            </div>
        </motion.div>
    )
  }
}

export default Card
