import React from 'react'
import { Home, About, Qualifications, Work } from '../container/index';
import TransitionEffect from '../components/transitionEffect/TransitionEffect';

const Accueil = () => {
  return (
    <div>
       <main className='main'>
        <TransitionEffect/>
        <Home/>
        <About/>
        <Qualifications/>
        <Work/>

      </main>
    </div>
  )
}

export default Accueil
