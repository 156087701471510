import React from 'react'
import "./home.scss"
import Social from './Social'
import Data from './Data'
import BackgroundAnimation from '../../components/background/BackgroundAnimation'

const Home = () => {
  return (
<section className="home section" id="home">
    <div className="home__container container grid">
        <div className="home__content grid">
            <Social/>
            {/* <div className="home__img"></div> */}
            <Data/>
            <BackgroundAnimation/>
        </div>
        {/* <ScrollDown/> */}
    </div>
</section>
  )
}

export default Home
