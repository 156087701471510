import React from 'react'
import { motion } from 'framer-motion'

var stylingObject = {
  h2: {
    fontSize: "var(--h2-font-size)",
    color:"var(--title-color)"
  }
}

class Heading2 extends React.Component {
    render(){
        return(
        <motion.h2 className="section__title"
          style={stylingObject.h2}
          initial={{ y:-70, opacity: 0 }}
          whileInView={{ y:0, opacity:1 }} 
          transition={{ duration:1, delay: 0.45, type:"spring" }}
        >
          {this.props.title}
        </motion.h2>
        )
    }
}

export default Heading2