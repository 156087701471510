import React, { useEffect, useState} from 'react'
// import { motion } from 'framer-motion'
import { urlFor, client } from '../../client';
import "./portfolio.scss";
import Works from '../../pages/Works';
import Card from '../card/Card';
import CtaButton from '../button/ButtonCta';

const Portfolio = () => {

  const [ portfolio, setPortfolio ] = useState([]);

  useEffect(() => {
    const query = `*[_type == "projects"][0...6]{
        title,
        slug,
        mainImage{
          asset->{
            _id, 
            url
          },
          alt
        },
        excerpt,
        link,
        projectType,
        categories,
        body,
    }`

    client.fetch(query)
      .then((data) => {
        setPortfolio(data)
        
      })

  }, []);

 

  return (
    <>
      <div 
        className='portfolio--wrapper'
        >
          { portfolio.map((portfolio, index) => (

              <Card
                altLink={ portfolio.title }
                to={ "/works/" + portfolio.slug.current }
                slug={ portfolio.slug.current }
                index={ index }
                src={portfolio.mainImage.asset.url}
                alt={portfolio.mainImage.alt}
                title={ portfolio.title }
                description={ portfolio.excerpt }       
                externLink={ portfolio.link }     
              
              />
          )) }

      </div>
      .
      <CtaButton 
          href="/works/" element={<Works />}
          label='En voir plus'
      />
    </>
  )
}

export default Portfolio