import SanityClient  from "@sanity/client";
import ImageUrlBuilder from "@sanity/image-url";

export const client = SanityClient({
    // projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    projectId: 'otk4j24v',
    dataset: 'production',
    apiVersion: '2022-12-01',
    useCdn: true,
    token: process.env.REACT_APP_SANITY_TOKEN,
});

const builder = ImageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);