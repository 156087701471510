import React, { useState, useEffect } from 'react'
import { client } from '../../client';
import Portfolio from '../../components/portfolio/Portfolio'
import Heading2 from '../../components/heading/Heading2'
import { motion } from "framer-motion";
import './work.scss'
import Text from '../../components/text/Text'

const Work = () => {

  const [works, setAbout] = useState([])

    useEffect(() => {
      const query = '*[_type == "works"]'

      client.fetch(query)
        .then((data) => {
            setAbout(data)
        })

    }, []);

  return (
    <section className="portfolio section" id="portfolio">
      {works.map((work, index) => (

          <div key={ index }>
            <Heading2 
              title={ work.name }
            />
           <motion.span 
           className="section__subtitle"
           initial={{ y:50, opacity:0 }}
                    whileInView={{ y:0, opacity:1 }}
                    transition={{ duration:1, type:"show", staggerChildren: 1.2,
                    delayChildren: 0.3, }}
           >
              { work.subtitle }
            </motion.span>
            <div className="work__container container grid">
                <div className="work__data">
                    <Text
                        className="work__description"
                        description={ work.description }
                    />
                </div>
            </div>
            <div className="portfolio__container container grid">
                <div className="portfolio__data">
                  <Portfolio></Portfolio>
                 
                </div>
            </div>
        </div>


      ))}
    </section> 
  )
}

export default Work
