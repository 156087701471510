import React from 'react'


const Social = () => {
  return (
    <div className="home__social">
        <a href="https://www.linkedin.com/in/fran%C3%A7ois-sarin-0949b1101/" className="home__social-icon" target='_blank' rel="noreferrer">
            <i class="uil uil-linkedin"></i>
        </a>
        {/* <a href="https://www.instagram.com/zefranck69/?hl=fr" className="home__social-icon" target='_blank' rel="noreferrer">
            <i class="uil uil-instagram"></i>
        </a> */}
        <a href="https://github.com/ZeFranck69" className="home__social-icon" target='_blank' rel="noreferrer">
            <i class="uil uil-github-alt"></i>
        </a>
    </div>
  )
}

export default Social
