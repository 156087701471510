import React from 'react'
import { motion } from 'framer-motion'

var stylingObject = {
    title: {
      fontSize: "var(--normal-font-size)",
      fontWeight: "var(--font-medium)",
      color:"var(--title-color)"
    }
  }

class Title extends React.Component {
    render(){
        return (
        <motion.h3 
            className={ this.props.className }
            style={ stylingObject.title }
            initial={{ y:-70, opacity: 0 }}
            whileInView={{ y:0, opacity:1 }} 
            transition={{ duration:1, delay: 0.45, type:"spring" }}>
                { this.props.title }
        </motion.h3>
    )};
}



export default Title
