import React from 'react'
import { motion } from 'framer-motion'
import { easeInOut } from 'popmotion'

var stylingObject = {
  h1: {
    fontSize: "var(--big-font-size)",
    marginBottom: "var(--mb-0-25)",
    
  }
}

class Heading1 extends React.Component {
    render(){
        return(
        <motion.h1 className="home__title"
          style={ stylingObject.h1 }
          initial={{ y:-70, opacity: 0 }}
          whileInView={{ y:0, opacity:1 }} 
          transition={{ duration:1, delay: 0.45, type:"spring" }}
        >
          {this.props.title}
        </motion.h1>
        )
    }
}

export default Heading1