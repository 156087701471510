import './App.scss';

// import { Home, About, Skills, Qualifications, Work } from './container/index';s
import { NavBar } from './components/index';
import Footer from './components/footer/Footer';
import Works from './pages/Works';
import {Routes, Route } from 'react-router-dom'
import Accueil from './pages/Accueil';
import Single from './pages/Single'

function App() {
  return (
    <>
      <div>
        <NavBar/>
          <Routes>
              <Route path="/" element={<Accueil />} />
              <Route path="/works/" element={<Works />} />
              <Route path="/works/:slug" element={<Single />} />
          </Routes>
          <Footer/>
      </div>
    </>
  );
}

export default App;
