import React, { useEffect, useState} from 'react'
import './tags.scss'

class Tags extends React.Component {
  render(){
      return(
          <div className="tag" key={this.props.key}>
            <img src={ this.props.img } alt={ this.props.name } title={ this.props.name } className="tag-image" />
          </div>
      )
  }
}
export default Tags