import React, { useState, useEffect } from 'react'
import { client } from '../../client';
import Heading1 from '../../components/heading/Heading1';
import Heading3 from '../../components/heading/Heading3';
import CtaContact from '../../components/button/ButtonContact';
import BlockContent from '@sanity/block-content-to-react';
import { motion } from 'framer-motion'

const Data = () => {

const [home, setHome] = useState([])

useEffect(() => {
const query = '*[_type == "home"]'

client.fetch(query)
.then((data) => {
setHome(data)
})

}, []);



return (
<div className="home__data">
    {home.map((home, index) => (

    <div key={index}>
        <Heading1
           title={ home.name }
        />
        <Heading3 
            subtitle={ home.subtitle }
        />
        <motion.div
           initial={{ y:50, opacity:0 }}
           whileInView={{ y:0, opacity:1 }}
           transition={{ duration:1, type:"show", staggerChildren: 1.2,
           delayChildren: 0.3, }}
           className="home__description"
        >
           <BlockContent blocks={ home.body }  projectId='otk4j24v' dataset='production' ></BlockContent>

        </motion.div>
        <CtaContact 
            href="mailto:francois.sarin.fr@gmail.com" 
            label='Contactez moi'
        />
    </div>


    
    ))}

</div>

)
}

export default Data