import React from 'react';
// import Text from '../components/text/Text'
import SinglePost from '../container/singlePost/SinglePost';
import TransitionEffect from '../components/transitionEffect/TransitionEffect';


const Single = () => {

    return (
        <div className="">
            <>
                <TransitionEffect></TransitionEffect>
              <SinglePost></SinglePost>
            </>
        </div>
    );
}

export default Single;
