import React, { useState, useEffect } from 'react';
import './about.scss';
import CV from "../../assets/François-Sarin-CV.pdf";
import Info from "../../components/Info";
import { urlFor, client } from '../../client';
import Heading2 from '../../components/heading/Heading2';
import CtaCV from '../../components/button/ButtonDownload';
import BlockContent from '@sanity/block-content-to-react';
import { motion } from "framer-motion";
const About = () => {

    const [about, setAbout] = useState([])

    useEffect(() => {
      const query = '*[_type == "about"]'

      client.fetch(query)
        .then((data) => {
            setAbout(data)
        })

    }, []);
    

    return (
        <section className="about section" id="about">
            {about.map((about, index) => (
                
            <div key={index}>
                <Heading2 
                    title={ about.name }
                />
                <motion.span 
                className="section__subtitle"
                initial={{ y:50, opacity:0 }}
                    whileInView={{ y:0, opacity:1 }}
                    transition={{ duration:1, type:"show", staggerChildren: 1.2,
                    delayChildren: 0.3, }}
                >
                    { about.subtitle }
                </motion.span>
                <div className="about__container container grid">
                    <motion.div 
                    className="about__data"
                    initial={{ y:50, opacity:0 }}
                    whileInView={{ y:0, opacity:1 }}
                    transition={{ duration:1, type:"show", staggerChildren: 1.2,
                    delayChildren: 0.3, }}
                    >
                        <Info />
                        <BlockContent
                            className="about__description"
                            blocks={ about.body }
                        />
                        <CtaCV
                            label='Télécharger le CV'
                            href={CV}
                        />
                        
                    </motion.div>
                </div>
            </div>
            
            
            ))}
            
            
        </section>
    )
}

export default About