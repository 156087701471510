import React from 'react'
import { motion } from 'framer-motion'

class Text extends React.Component {
    render(){
        return (
        <motion.p 
            className={ this.props.className }
            initial={{ y:-70, opacity: 0 }}
            whileInView={{ y:0, opacity:1 }} 
            transition={{ duration:1, delay: 0.45, type:"spring" }}>
                { this.props.description }
        </motion.p>
    )};
}

export default Text
