import React, { useState, useEffect, useRef } from 'react'
import './qualifications.scss'
import { client } from '../../client';
import Heading2 from '../../components/heading/Heading2';
import BlockContent from '@sanity/block-content-to-react';
import Panel from '../../components/panel/Panel';
import { motion, useScroll } from "framer-motion";


const Qualifications = () => {
const [experiences, setExperiences] = useState([])

  useEffect(() => {
    const query = '*[_type == "experiences"]'

    client.fetch(query)
      .then((data) => {
        setExperiences(data)
      })

}, []);


return (
  <section className="qualifications section" id="experience">
    {experiences.map((experience, index) => (
      <div key={index} className='container'>
          <Heading2 
            title={ experience.title }
          />
          <motion.div 
          className="experience__container container"
          >
              
            <BlockContent
                className="experience__description"
                blocks={ experience.body }
            />
              
          </motion.div>
          <div className="experience__wrapper " >
            <div className='experiences' >

            { experience.experiences.map((exp, index) => (
                <Panel
                  className="panel-experience panel"  
                  title={exp.name}
                  subtitle={exp.company}
                  date={exp.date}
                  description={exp.body}
                  index={index}
                  place={exp.place}
                />
            ))}
            </div>
          </div>
      </div>
    ))}
    </section>
  )
}

export default Qualifications