import React from 'react'
import ArchiveProjects from '../container/archiveProjects/ArchiveProjects'
import TransitionEffect from '../components/transitionEffect/TransitionEffect'


const Works = () => {
  return (
    <div className='portfolio section' id='porfolio'>
     
      <TransitionEffect/>
      <ArchiveProjects>

      </ArchiveProjects>
    </div>
  )
}

export default Works
