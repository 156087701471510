import React, { useState, useEffect } from 'react';
import "./navbar.scss";
import {Route, Link, Routes} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavBar = () => {
    // Toggle menu
    const [ activeLink, setActiveLink ] = useState('home');
    const [Toggle, showMenu] = useState(false);
    const [ scrolled, setScrolled ] = useState(false);

    useEffect(() => {
        const onScroll = () =>{
            if(window.scrollY > 100){
                setScrolled(true);
            }else{
                setScrolled(false);
            }
        }

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
       
    }

    return (
    <header className= { scrolled ? 'header scrolled' : 'header' } >
        <nav className={Toggle ? 'nav container show' : 'nav container hide'}>
            <Link to="/" className="nav__logo">
                <span className="firstname">François </span>
                <span className='lastname'>Sarin</span>
            </Link>
            <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
                <ul className="nav__list">

                    
                    {['About', 'Experience'].map((item)=>(
                    <li className="nav__item" key={`link-${item}`}>
                        
                        <HashLink smooth to={`/#${item}`.toLowerCase()}  className={activeLink === item.toLowerCase() ? 'nav__link active-link' : 'nav__link' } onClick={() => onUpdateActiveLink(item.toLowerCase())}>
                            {`${item}`} 
                        </HashLink>
                    </li>

                    )) }
                        
                    <li className="nav__item" key="link-portfolio">
                        <Link to="/works" className={activeLink === "portfolio" ? 'nav__link active-link' : 'nav__link' } onClick={() => onUpdateActiveLink("portfolio")}>
                            Portfolio 
                        </Link>
                    </li>
                        
                    

                    <li className="nav__item" key="link-contact">
                        
                        <a href="mailto:francois.sarin.fr@gmail.com"  className={activeLink === "contact" ? 'nav__link active-link' : 'nav__link' } onClick={() => onUpdateActiveLink("contact")}>
                            Contact 
                        </a>
                    </li>
                </ul>

            </div>
            <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
                <i className="uil uil-apps"></i>
            </div>
            <i className="uil uil-times nav__close"  onClick={() => showMenu(!Toggle)}></i>
        </nav>
    </header>
  )
}

export default NavBar


