import React, { Component, useRef } from 'react'
import HeadingPanel from '../../components/heading/HeadingPanel';
import BlockContent from '@sanity/block-content-to-react';
import { motion } from "framer-motion";
import './panel.scss'

export class Panel extends Component {
  render() {
    return (
      <motion.div 
        className={this.props.className} 
        // key={this.props.index}
        // initial={{ y:50 }}
        // whileInView={{ y:0 }}
        // transition={{ duration:1, type:"show", staggerChildren: 1.2,
        // delayChildren: 0.3, }}
      >
        <motion.div 
          className="panel-left"
          // initial={{ x:-75 }}
          // whileInView={{ x:0 }}
          // transition={{ duration:1, type:"spring" }}
        >
          <div className="panel-left__calendar">
              <i className="uil uil-calendar-alt"></i>
                <span className="calendar-date"> { this.props.date } </span>
          </div>
          <div className="panel-left__subtitle">

            <i class="uil uil-map-marker"></i>
            <span className="subtitle-company">{ this.props.subtitle }</span> 
            <span className='separator'>-</span>
            <span className="subtitle-place"> { this.props.place } </span> 
          </div>
        </motion.div>
        <motion.div 
          className="panel-right"
          // initial={{ x:75 }}
          // whileInView={{ x:0 }}
          // transition={{ duration:1, type:"spring" }}
        >
          <HeadingPanel 
              title={ this.props.title }
              className="panel-right__title"
          />
          <BlockContent
              blocks={ this.props.description }
              projectId='otk4j24v' 
              dataset='production'
              className='panel-right__description'
          />
        </motion.div>
      </motion.div>
    )
  }
}

export default Panel
