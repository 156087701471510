import React, { useState, useEffect } from 'react'
import './archiveProjects.scss'
import {  client } from '../../client';
import Card from '../../components/card/Card';
import { FallingLines } from  'react-loader-spinner'
import Heading2 from '../../components/heading/Heading2';

const ArchiveProjects = () => {
    const [ projects, setProject ] = useState([]);

  useEffect(() => {
    const query = `*[_type == "projects"]{  
        title,
        slug,
        mainImage{
          asset->{
            _id, 
            url
          },
          alt
        },
        link,
        excerpt,
        projectType,
        categories,
        body,
    }
    `

    client.fetch(query)
      .then((data) => {
        setProject(data)
      })

  }, []);

  return (
    <div className="section container">
        <Heading2
          title="Mes projets"
          className="archive-title"
        />
             
         
        <div className="archive-list">
        { projects.map((project, index) => (
          <>
            {
              !project
              ?
              <FallingLines
                color="#4fa94d"
                width="100"
                visible={true}
                ariaLabel='falling-lines-loading'
              />
              
              :
              <Card
                altLink={ project.title }
                to={ "/works/" + project.slug.current }
                slug={ project.slug.current }
                id={ index }
                src={project.mainImage.asset.url}
                alt={project.mainImage.alt}
                title={ project.title }
                description={ project.excerpt }
                externLink={ project.link }
              />
            }
          </>
          )) }            
        </div>
    </div>
  )
}

export default ArchiveProjects