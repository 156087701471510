import React, { useEffect, useState } from 'react'
import { urlFor, client } from '../../client';
import { useParams } from 'react-router'
import BlockContent from '@sanity/block-content-to-react';
import './singlePost.scss'
import Tags from '../../components/tags/Tags';
import { FallingLines } from  'react-loader-spinner'

const SinglePost = () => {

  const [ singlePost, setSinglePost ] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const query = `*[slug.current == "${slug}"] {
      title,
      _id,
      slug, 
      mainImage{ 
        asset->{
          _id,
          url
        }
      },
      body,
      "categories": categories[]-> { 
        name, 
        icon{
          asset->{
            _id,
            url
          }
        } 
      },
     
    }`
    

    client.fetch(query)
    
    .then((data) => {
      setSinglePost(data[0])
      
    })
    
  }, [slug])
 
  
  if(!singlePost) {
    return <div> loading ...</div>
  }

  return (
    <>
      {
        !singlePost 
        ?
        <FallingLines
          color="#4fa94d"
          width="100"
          visible={true}
          ariaLabel='falling-lines-loading'
        />
        :
      <div className="section singlePost">
        <div className="single-banner">
          <img src={ singlePost.mainImage.asset.url } alt="singlePost.mainImage.asset.title" className='banner-image' />
        </div>

        <article className="container single-container">
        
          
          <main className="single-main">
            <div className=''>
              <h1 className='single-title'> {singlePost.title} </h1>
              {/* Ajouter le bouton aller sur le site */}
            </div>
            <div className="tags-wrapper">
              { singlePost.categories && singlePost.categories.map((tag, index) => (
                <Tags
                  name = { tag.name }
                  img = { tag.icon.asset.url }
                  key={ index }
                ></Tags>
              ))}
            </div>
            <div className="content-single">
              <BlockContent blocks={ singlePost.body }  projectId='otk4j24v' dataset='production' ></BlockContent>
            </div>
          </main>
          
            
      </article>
      </div>
      }
    </>
  )
}

export default SinglePost